import React, {useContext, useEffect, useState} from "react";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
import {useAPI} from "./APIProvider";

const IsAdminContext = React.createContext(null);

/**
 * The IsAdmin provider provides whether or not the user is an admin to other components.
 *
 * @param children
 * @returns {Element}
 * @constructor
 */
export const IsAdminProvider = ({ children }) => {
  const api = useAPI();
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    api.isAdmin().then((data) => {
        setIsAdmin(data.is_admin);
    });
  }, [api, isAdmin]);

  if (isAdmin === null) {
    return <ApplicationBootingPage />;
  }

  return (
      <IsAdminContext.Provider value={isAdmin}>
        {children}
      </IsAdminContext.Provider>
  );
};

export const useIsAdmin = useContext.bind(null, IsAdminContext);
