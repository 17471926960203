import axios from "axios";

axios.defaults.showAxiosError = false;

const api = {
    async getWA2ChatConfiguration() {
        const response = await axios.get(`/wa_2chat/configuration`);
        return response.data;
    },

    async getJoinedGroups(phoneNumber) {
        const params = {
            phoneNumber: phoneNumber
        }

        const response = await axios.get(`/wa_2chat/joined_groups`, {params: params});
        return response.data;
    },

    async saveWA2ChatConfiguration(changes) {
        const response = await axios.put(`/wa_2chat/configuration`, changes);
        return response.data;
    },
}

export default api;