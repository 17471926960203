import { api } from "../../../frontend/components/api";

class TranslationManager {
  constructor(api) {
    this.api = api;
    this.cacheKey = "translations_cache";
    this.cache = {};
    this.promises = {};
    this.loadCache();
    this.enabled = false;
  }

  async fetchTranslation(sourceText, targetLanguageCode) {
    if (!targetLanguageCode) {
      targetLanguageCode = this.getLocalLanguage();
    }

    const translationKey = this.computeTranslationCacheKey(
      sourceText,
      targetLanguageCode
    );

    if (this.cache[translationKey]) {
      return this.cache[translationKey].target_text;
    }

    if (this.promises[translationKey]) {
      return (await this.promises[translationKey]).target_text;
    }

    try {
      const translationPromise = this.api.translate(
        sourceText,
        targetLanguageCode
      );
      this.promises[translationKey] = translationPromise;
      const translation = await translationPromise;
      this.cache[translationKey] = translation;
      this.saveCache();
      return translation.target_text;
    } catch (error) {
      console.error("Error fetching translations:", error);
      this.promises[translationKey] = null;
      throw error;
    }
  }

  loadCache() {
    try {
      this.cache = JSON.parse(localStorage.getItem(this.cacheKey)) || {};
    } catch (error) {
      console.error("Error loading translations cache:", error);
      this.cache = {};
    }
  }

  saveCache() {
    localStorage.setItem(this.cacheKey, JSON.stringify(this.cache));
  }

  getTranslationIfAvailableSync(sourceText, targetLanguageCode) {
    if (!targetLanguageCode) {
      targetLanguageCode = this.getLocalLanguage();
    }

    const translationKey = this.computeTranslationCacheKey(
      sourceText,
      targetLanguageCode
    );
    if (this.cache[translationKey]) {
      return this.cache[translationKey].target_text;
    }

    // If it's not available in the cache, we return the original source text,
    // but we also tee up a request to fetch the translation so that the next time
    // the translation is requested, it might be available
    this.fetchTranslation(sourceText, targetLanguageCode).then(() => {
      // Do nothing, we're just fetching the translation to cache it
    });

    return sourceText;
  }

  getTranslationIfAvailableAndEnabledSync(sourceText, targetLanguageCode) {
    if (this.enabled) {
      return this.getTranslationIfAvailableSync(sourceText, targetLanguageCode);
    } else {
      return sourceText;
    }
  }

  computeTranslationCacheKey(sourceText, targetLanguageCode) {
    return `${sourceText}_${targetLanguageCode}`;
  }

  getLocalLanguage() {
    return navigator.language || navigator.userLanguage;
    // return "es";
  }

  getLocalLanguageCode() {
    return this.getLocalLanguage().split("-")[0];
  }
}

const globalTranslationManager = new TranslationManager(api);
export default globalTranslationManager;
