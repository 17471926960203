import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

const axiosWithETAGCache = axiosETAGCache(axios);

const api = {
  async uploadImageProfile(payload) {
    const response = await axios.post(`/image`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },

  async getImage(imageId) {
    const response = await axiosWithETAGCache.get(`/image/${imageId}`);
    return response.data;
  },

  async getImageContent(imageId) {
    const response = await axiosWithETAGCache.get(
      `/image/${imageId}/contents`,
      {
        params: {
          return_base64: true,
        },
      }
    );
    return response.data;
  },
};

export default api;
