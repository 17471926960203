import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";


let globalAvailableActions = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getAvailableActions() {
        if (globalAvailableActions) {
            return globalAvailableActions;
        }
        const response = await axiosWithETAGCache.get(`/available_actions`);
        globalAvailableActions = response.data;
        return response.data;
    },
}

export default api;

