import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../../../core/frontend/utils/time";
import SSEStreamer from "../../../core/frontend/components/SSEStreamer";
import {axiosETAGCache} from "axios-etag-cache";

let globalPromptChartSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getPromptChartSchema() {
        if (globalPromptChartSchema) {
            return globalPromptChartSchema;
        }
        const response = await axiosWithETAGCache.get(`/chart/schema`);
        globalPromptChartSchema = response.data;
        return response.data;
    },
    async createNewChart(newChart) {
        const body = newChart;

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/chart`, body), 1000);

        return response.data;
    },
    async getCharts(templateId) {
        const query = {};
        if (templateId) {
            query.template_id = templateId;
        }

        const response = await axiosWithETAGCache.get(`/chart`, {params: query});

        return response.data;
    },
    async getChartsTable(tableQuery) {
        const query = {
            query: JSON.stringify(tableQuery)
        };

        const response = await axiosWithETAGCache.get(`/chart_table`, {params: query});

        return response.data;
    },
    async getChart(chartId) {
        const body = {}

        const response = await axiosWithETAGCache.get(`/chart/${chartId}`, body);

        return response.data;
    },
    async saveChart(chart) {
        const body = chart;

        const response = await axios.put(`/chart/${chart.id}`, body);

        return response.data;
    },
    async patchChart(chartId, chartPatch) {
        const body = chartPatch;

        const response = await axios.patch(`/chart/${chartId}`, body);

        return response.data;
    },
    async changeChartTitle(chartId, newTitle) {
        return api.changeChartValues(chartId, {title: newTitle});
    },
    async changeChartValues(chartId, newShareOptions) {
        const body = Object.keys(newShareOptions).map((key) => ({
            "op": "add",
            "path": `/${key}`,
            "value": newShareOptions[key],
        }));

        const response = await ensureMinimumPromiseResolveTime(axios.patch(`/chart/${chartId}`, body), 500);

        return response.data;
    },

    async getChartsForUserAccount() {
        const response = await axiosWithETAGCache.get(`/chart`);

        return response.data;
    },

    async recomputeChart(chartId, chartData) {
        const body = chartData ?? {};

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/chart/${chartId}/recompute`, body), 500);

        return response.data;
    },
    async anonymousRecomputeChart(chartData) {
        return api.recomputeChart("default", chartData);
    },
    recomputeChartStreaming(chartId, chartData) {
        const body = chartData ?? {};

        const streamUri = axios.defaults.baseURL + `chart/${chartId}/recompute_streaming`;

        // Make the streaming call with the same headers as a normal axios call, which will include the
        // authentication token if it's available
        const headers = axios.defaults.headers.common;

        // Construct the SSE streamer and return it.
        return new SSEStreamer(streamUri, "POST", body, headers);
    },
    anonymousRecomputeChartStreaming(chartData) {
        return api.recomputeChartStreaming("default", chartData);
    },
    async deleteChart(chartId) {
        const response = await ensureMinimumPromiseResolveTime(axios.delete(`/chart/${chartId}`), 1000);

        return response.data;
    },
    async getPromptChartTemplateSampleValues(template_id, field_name) {
        const response = await axios.get(`/prompt_chart_templates/${template_id}/fields/${field_name}/sample_values`);

        return response.data;
    },
    async generateImage(prompt) {
        const response = await axios.get(`/generate_image`, {
                params: {prompt},
                responseType: "blob",
            }
        );

        return response.data;
    },
    async cancelRecomputeChart(chartId) {
        const response = await ensureMinimumPromiseResolveTime(axios.post(`/chart/${chartId}/cancel_recompute`), 500);

        return response.data;
    }
}

export default api;