import "./TopBar.scss";
import AccountMenu from "./AccountMenu";
import EnvironmentTitleMenu from "./EnvironmentTitleMenu";
import {useFrontendConfiguration} from "../components/FrontendConfigurationProvider";
import {Fragment} from "react";


export function TopBar({injectedTopBarItems}) {
    const config = useFrontendConfiguration();

    let logoAreaClass = "logo-area";

    if (config?.frontend?.enable_environment_menu) {
        logoAreaClass += " environment-menu-enabled";
    }

    return <div className={"top-bar"} data-testid={"top-bar"}>
        {
            config ?
                <>
                    <div className={logoAreaClass}>
                        {
                            config?.frontend?.logo_url !== "" ?
                                <div className={"logo-image"}>
                                    <img
                                        src={config?.frontend?.logo_url}
                                        alt={config?.frontend?.top_bar_title}
                                        className={"logo-image"}
                                    />
                                </div>
                                : null
                        }
                        {
                            config?.frontend?.logo_url === "" ?
                                config?.frontend?.enable_environment_menu
                                    ? <EnvironmentTitleMenu/>
                                    : <span className={"logo-text"}>
                                    {config?.frontend?.top_bar_title}
                                  </span>
                                : null
                        }
                    </div>

                    {injectedTopBarItems}

                    <AccountMenu/>
                </>
                : null
        }
    </div>
}

export default TopBar;
