import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./EnvironmentTitleMenu.scss";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import SchemaIcon from '@mui/icons-material/Schema';
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import {useFrontendConfiguration} from "../components/FrontendConfigurationProvider";
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';


export default function EnvironmentTitleMenu() {
  const config = useFrontendConfiguration();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReceptionistClick = () => {
      window.location.href = "https://receptionist.prosperalabs.ai/"
  }

  const onSalesBotClick = () => {
      window.location.href = "https://salesbot.prosperalabs.ai/"
  }

  const onNetworkingBotClick = () => {
      window.location.href = "https://networkingbot.prosperalabs.ai/"
  }

  const onFlowThoughtClick = () => {
      window.location.href = "https://flowthought.prosperalabs.ai/"
  }

  const onKnowledgeBotClick = () => {
      window.location.href = "https://knowledgebot.prosperalabs.ai/"
  }

  return (
    <div className={"environment-menu"}>
      <Button
        id="environment-title-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={"title-button"}
        onClick={handleClick}
      >
        {config.frontend.top_bar_title}
      </Button>
      <Menu
        id="environment-selector"
        className={"environment-selector"}
        aria-labelledby="environment-title-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onReceptionistClick}><PhoneInTalkIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Receptionist" /></MenuItem>
        <MenuItem onClick={onSalesBotClick}><MonetizationOnIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Sales Bot"/></MenuItem>
        <MenuItem onClick={onNetworkingBotClick}><GroupsIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Networking Bot"/></MenuItem>
        <MenuItem onClick={onFlowThoughtClick}><SchemaIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="FlowThought" /></MenuItem>
        <MenuItem onClick={onKnowledgeBotClick}><LightbulbCircleIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Knowledge Bot" /></MenuItem>
      </Menu>
    </div>
  );
}

