import React, {useEffect} from "react";
import {api} from "./api";
import {useSnackbar} from "./SnackbarProvider";

const pingInterval = 60000; // Ping backend every minute to keep it awake
let globalCurrentVersion = "";

/**
 * The ping provider calls the home endpoint of the API as soon as the application starts up.
 *
 * It's meant to trigger the app to start cold-booting if it needs to, and to continuing
 * pinging it to ensure it stays awake while it is in use.
 *
 * Notably the first ping is done before the authentication flow or APIProvider.
 * By making the first ping happen before login, we can give the backend server time to boot up
 * while the user is logging in, reducing the time the user has to wait significantly.
 */
export const BackendPingWrapper = ({children}) => {
    const snackbar = useSnackbar();

    useEffect(() => {
        const handle = setInterval(() => {
            api.home().then((data) => {
                if (data.version) {
                    if (globalCurrentVersion) {
                        if (globalCurrentVersion !== data.version) {
                            snackbar.toast({
                                message: "A new version of the application is available. Please hold shift and click Reload on your browser to update.",
                                severity: "warning",
                                autoHideMs: 30000
                            });
                        } else {
                            // Version hasn't changed. Do nothing.
                        }
                    } else {
                        globalCurrentVersion = data.version;
                    }
                }
            }).catch(() => {
                // Ignore errors
            });
        }, pingInterval);

        return () => clearInterval(handle);
    }, [snackbar]);

    return <>
        {children}
    </>;
};

