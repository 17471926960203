import "./LeftSideMenu.scss";
import * as React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Collapse from '@mui/material/Collapse';
import CommentIcon from '@mui/icons-material/Comment';
import CommitIcon from '@mui/icons-material/Commit';
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import DifferenceIcon from "@mui/icons-material/Difference";
import DvrIcon from "@mui/icons-material/Dvr";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LinkIcon from '@mui/icons-material/Link';
import ListItemIcon from "@mui/material/ListItemIcon";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import MessageIcon from "@mui/icons-material/Message";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import NewspaperIcon from "@mui/icons-material/Newspaper";
import OutboundIcon from "@mui/icons-material/Outbound";
import PeopleIcon from "@mui/icons-material/People";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PortraitIcon from "@mui/icons-material/Portrait";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ScheduleIcon from '@mui/icons-material/Schedule';
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsIcon from '@mui/icons-material/Settings';
import TopicIcon from "@mui/icons-material/Topic";
import WebIcon from "@mui/icons-material/Web";
import {Fragment, useCallback, useContext} from "react";
import {IconButton, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useAPI, useIsAuthenticated} from "../components/APIProvider";
import {useFrontendConfiguration} from "../components/FrontendConfigurationProvider";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

const globalOpenLeftMenusByDefault = window.location.search.includes("open-menus");


const LeftSideMenuStateContext = React.createContext(null);
export const LeftSideMenuStateProvider = ({children}) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const leftMenuStateInterface = {
    isExpanded,
    setIsExpanded,
  }

  return <LeftSideMenuStateContext.Provider value={leftMenuStateInterface}>
    {children}
  </LeftSideMenuStateContext.Provider>
}
export const useLeftMenuState = useContext.bind(null, LeftSideMenuStateContext);


function LeftSideMenuEntry({link, icon, text, onClick}) {
  return <ListItem
    className={"left-side-menu-entry"}
    disablePadding>
    <NavLink
      to={link}
      onClick={onClick}
      className={({isActive, isPending}) => {
        return isActive ? "active" : isPending ? "pending" : "";
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <LocalTranslatedText
              language={"en"}
              text={text}
            />
          }
        />
      </ListItemButton>
    </NavLink>
  </ListItem>
}

function LeftSideMenuSection({title, icon, links, children}) {
    const frontendConfiguration = useFrontendConfiguration();

  // Determine if the page should be open by checking if the current page is in the list of links
  let defaultOpen = links.includes(window.location.pathname) || links.includes(window.location.pathname.replace(frontendConfiguration.router_basename, "")) || globalOpenLeftMenusByDefault;
  const [open, setOpen] = React.useState(defaultOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItemButton
        onClick={handleClick}
        className={"left-side-menu-entry"}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title}/>
        {open ? <ExpandLess/> : <ExpandMore/>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
}

function LeftSideMenuEntryWithAction({link, icon, text, onClick, onExpandClicked, isExpanded}) {
  return (<ListItem
      disablePadding
      className={"left-side-menu-entry"}
    >
      <NavLink
        to={link}
        onClick={onClick}
        className={({isActive, isPending}) => {
          return isActive ? "active" : isPending ? "pending" : "";
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <LocalTranslatedText
                language={"en"}
                text={text}
              />
            }
          />
          <IconButton
            edge="end"
            aria-label="expand"
            onClick={onExpandClicked}
          >
            {
              isExpanded ? <ChevronLeftIcon/> : <ChevronRightIcon/>
            }
          </IconButton>
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}


export default function LeftSideMenu() {
  const config = useFrontendConfiguration();
  const api = useAPI();
  const {isExpanded, setIsExpanded} = useLeftMenuState();

  const handleExpandClicked = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleBillingClick = useCallback(
    (evt) => {
      evt.preventDefault();
      api.getBookingPortalUrl().then((data) => {
        window.location.href = data.url;
      });
    },
    [api]
  );

  return <div className={`left-side-menu ${isExpanded ? 'expanded' : ''}`}>
    <div className={"menu-pages-wrapper"} data-testid={"left-side-menu"}>
      <List>
        <LeftSideMenuEntryWithAction
          link={"/"}
          icon={<DashboardIcon/>}
          text={"Home"}
          onExpandClicked={handleExpandClicked}
          isExpanded={isExpanded}
        />
        {config.modules.bot ? (
          <LeftSideMenuEntry
            link={"/conversations"}
            icon={<MessageIcon/>}
            text={"Conversations"}
          />
        ) : null}
        {config.modules.intake ? (
          <LeftSideMenuEntry
            link={"/intakes"}
            icon={<AssignmentIcon/>}
            text={`Intake Surveys`}
          />
        ) : null}
        {
          config.modules.scheduling ? (
            <LeftSideMenuSection
              title={"Scheduling"}
              icon={<ScheduleIcon/>}
              links={[
                "/calendars",
                "/scheduling_rules",
                "/bookings"
              ]}
            >
              <LeftSideMenuEntry
                link={"/calendars"}
                icon={<CalendarTodayIcon/>}
                text={"Calendars"}
              />
              <LeftSideMenuEntry
                link={"/scheduling_rules"}
                icon={<AccessTimeIcon/>}
                text={"Scheduling Rules"}
              />
              <LeftSideMenuEntry
                link={"/bookings"}
                icon={<EventNoteIcon/>}
                text={"Bookings"}
              />
            </LeftSideMenuSection>
          ) : null
        }
        {
          config.modules.web_chat ?
            <LeftSideMenuSection
              title={"Web Chat"}
              icon={<CommentIcon/>}
              links={[
                "/chat",
                "/web_chat_configuration"
              ]}
            >
              <LeftSideMenuEntry
                link={"/chat"}
                icon={<SendIcon/>}
                text={"Chat with your Bot"}
              />

              <LeftSideMenuEntry
                link={"/web_chat_configuration"}
                icon={<ConnectedTvIcon/>}
                text={"Web Chat Config"}
              />

            </LeftSideMenuSection>
            : null
        }
        {
          config.modules.knowledge_base ?
            <LeftSideMenuSection
              title={"Knowledge Base"}
              icon={<LightbulbIcon/>}
              links={[
                "/imported_documents",
                "/imported_webpages",
                "/knowledge",
                "/knowledge_base_configuration"
              ]}
            >
              <LeftSideMenuEntry
                link={"/imported_documents"}
                icon={<ArticleIcon/>}
                text={"Imported Documents"}
              />
              <LeftSideMenuEntry
                link={"/imported_webpages"}
                icon={<WebIcon/>}
                text={`Imported Webpage`}
              />
              <LeftSideMenuEntry
                link={"/knowledge"}
                icon={<ModelTrainingIcon/>}
                text={"Knowledge"}
              />
              <LeftSideMenuEntry
                link={"/knowledge_base_configuration"}
                icon={<SettingsApplicationsIcon/>}
                text={"Knowledge Base Configuration"}
              />
            </LeftSideMenuSection>
            : null
        }
        {
          config.modules.document_generator ?
            <LeftSideMenuSection
              title={"AI Document Generation"}
              icon={<AssignmentIcon/>}
              links={[
                "/document_template",
                "/generated_document"
              ]}
            >
              <LeftSideMenuEntry
                link={"/document_template"}
                icon={<DescriptionIcon/>}
                text={`Document Templates`}
              />
              <LeftSideMenuEntry
                link={"/generated_document"}
                icon={<ArticleIcon/>}
                text={`Generated Documents`}
              />
            </LeftSideMenuSection>
            : null
        }

        {config.modules.legacy_networkingbot ? (
          <LeftSideMenuEntry
            link={"/contacts"}
            icon={<PeopleIcon/>}
            text={"Contacts"}
          />
        ) : null}
        {config.modules.legacy_remindertap ? (
          <LeftSideMenuEntry
            link={"/insurance_agents"}
            icon={<EngineeringIcon/>}
            text={"Insurance Agents"}
          />
        ) : null}
        {config.modules.legacy_remindertap ? (
          <LeftSideMenuEntry
            link={"/customers"}
            icon={<PeopleIcon/>}
            text={"Customers"}
          />
        ) : null}
        {config.modules.legacy_mybookrobot ? (
          <LeftSideMenuEntry
            link={"/generate_story"}
            icon={<AutoStoriesIcon/>}
            text={"Generate Story"}
          />
        ) : null}
        {config.modules.legacy_mybookrobot ? (
          <LeftSideMenuEntry
            link={"/story"}
            icon={<DvrIcon/>}
            text={"List Stories"}
          />
        ) : null}
        {config.modules.legacy_mybookrobot ? (
          <LeftSideMenuEntry
            link={"/story_prompt_configuration"}
            icon={<DescriptionIcon/>}
            text={"Prompt Configuration"}
          />
        ) : null}
        {config.modules.legacy_wordfactory ? (
          <LeftSideMenuEntry
            link={"/content_samples"}
            icon={<ArticleIcon/>}
            text={"Content Samples"}
          />
        ) : null}
        {config.modules.legacy_wordfactory ? (
          <LeftSideMenuEntry
            link={"/topic_extractor_prompts"}
            icon={<TopicIcon/>}
            text={"Topic Prompts"}
          />
        ) : null}
        {config.modules.legacy_wordfactory ? (
          <LeftSideMenuEntry
            link={"/transcript_processor_configurations"}
            icon={<EditNoteIcon/>}
            text={"Processor Configs"}
          />
        ) : null}
        {config.modules.legacy_wordfactory ? (
          <LeftSideMenuEntry
            link={"/process_transcript"}
            icon={<DifferenceIcon/>}
            text={"Process New Transcript"}
          />
        ) : null}
        {config.modules.legacy_wordfactory ? (
          <LeftSideMenuEntry
            link={"/generated_content"}
            icon={<NewspaperIcon/>}
            text={"Generated Content History"}
          />
        ) : null}
        {config.modules.legacy_proposal ? (
          <LeftSideMenuEntry
            link={"/proposals"}
            icon={<ReceiptIcon/>}
            text={"Proposals"}
          />
        ) : null}

        <LeftSideMenuSection
          title={"Configuration"}
          icon={<SettingsApplicationsIcon/>}
          links={[
            "/smart_chains",
            "/smart_chain_binding",
            "/tenant_configuration",
            "/personal_info",
            "/business_info",
            "/voice_configuration",
            "/wa_2chat_configuration",
            "/subscription",
            "/prompt_charts",
            "/default_outbound_rules",
            "/synthetic_user",
            "/system_health",
          ]}
        >
          {config.modules.legacy_networkingbot ? (
            <LeftSideMenuEntry
              link={"/personal_info"}
              icon={<SettingsAccessibilityIcon/>}
              text={"Personal Info"}
            />
          ) : null}
          {config.modules.business_info ? (
            <LeftSideMenuEntry
              link={"/business_info"}
              icon={<BusinessIcon/>}
              text={"Business Info"}
            />
          ) : null}
          {config.modules.voice ? (
            <LeftSideMenuEntry
              link={"/voice_configuration"}
              icon={<PhoneInTalkIcon/>}
              text={"Voice Configuration"}
            />
          ) : null}
        {config.modules.whatsapp ? (
            <LeftSideMenuEntry
              link={"/wa_2chat_configuration"}
              icon={<PhoneInTalkIcon/>}
              text={"2Chat Configuration"}
            />
          ) : null}
          {config.modules.billing ? (
            <LeftSideMenuEntry
              link={"/subscription"}
              icon={<SellIcon/>}
              text={"Billing & Subscription"}
              onClick={handleBillingClick}
            />
          ) : null}
          {config.modules.outbound ? (
            <LeftSideMenuEntry
              link={"/default_outbound_rules"}
              icon={<OutboundIcon/>}
              text={`${config.frontend.default_outbound_rules_word} Rules`}
            />
          ) : null}

        <LeftSideMenuSection
            title={"Advanced"}
            icon={<ArrowCircleDownIcon/>}
            links={[
                "/smart_chains",
                "/smart_chain_binding",
                "/tenant_configuration",
                "/prompt_charts",
                "/synthetic_user",
                "/system_health",
            ]}
        >

          <LeftSideMenuEntry
            link={"/smart_chains"}
            icon={<LinkIcon/>}
            text={"Smart Chains"}
          />
          <LeftSideMenuEntry
            link={"/smart_chain_binding"}
            icon={<CommitIcon/>}
            text={"Smart Chain Bindings"}
          />
          {config.modules.tenant ? (
            <LeftSideMenuEntry
              link={"/tenant_configuration"}
              icon={<SettingsIcon/>}
              text={"Tenants"}
            />
          ) : null}
          {config.modules.prompt_chart ? (
            <LeftSideMenuEntry
              link={"/prompt_charts"}
              icon={<AccountTreeIcon/>}
              text={"Prompt Charts"}
            />
          ) : null}
          {config.modules.bot ? (
            <LeftSideMenuEntry
              link={"/synthetic_user"}
              icon={<PortraitIcon/>}
              text={"Synthetic User"}
            />
          ) : null}
            <LeftSideMenuEntry
              link={"/system_health"}
              icon={<MonitorHeartIcon/>}
              text={"System Health"}
            />
            </LeftSideMenuSection>
        </LeftSideMenuSection>
      </List>
    </div>
  </div>;
}
