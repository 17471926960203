import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalImportedDocumentSchema = null;
let globalImportedWebpageSchema = null;
let globalKnowledgeChunkSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getImportedDocumentSchema() {
    if (globalImportedDocumentSchema) {
      return globalImportedDocumentSchema;
    }
    const response = await axiosWithETAGCache.get(`/imported_document/schema`);
    globalImportedDocumentSchema = response.data;
    return response.data;
  },
  async getImportedDocuments(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/imported_document`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewImportedDocument(newDocumentData) {
    const response = await axios.post(`/imported_document`, newDocumentData);

    return response.data;
  },
  async getImportedDocument(documentId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/imported_document/${documentId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveImportedDocument(document) {
    const response = await axios.put(
      `/imported_document/${document._id}`,
      document
    );
    return response.data;
  },
  async deleteImportedDocument(documentId) {
    const response = await axios.delete(`/imported_document/${documentId}`);

    return response.data;
  },
  async getKnowledgeChunkSchema() {
    if (globalKnowledgeChunkSchema) {
      return globalKnowledgeChunkSchema;
    }
    const response = await axiosWithETAGCache.get(`/knowledge_chunk/schema`);
    globalKnowledgeChunkSchema = response.data;
    return response.data;
  },
  async getKnowledgeChunks(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/knowledge_chunk`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewKnowledgeChunk(newKnowledgeChunkData) {
    const response = await axios.post(
      `/knowledge_chunk`,
      newKnowledgeChunkData
    );

    return response.data;
  },
  async getKnowledgeChunk(knowledgeChunkId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/knowledge_chunk/${knowledgeChunkId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveKnowledgeChunk(knowledge_chunk) {
    const response = await axios.put(
      `/knowledge_chunk/${knowledge_chunk._id}`,
      knowledge_chunk
    );
    return response.data;
  },
  async deleteKnowledgeChunk(knowledgeChunkId) {
    const response = await axios.delete(`/knowledge_chunk/${knowledgeChunkId}`);

    return response.data;
  },
  async getKnowledgeBaseConfiguration() {
    const response = await axios.get(`/knowledge_base_configuration`);
    return response.data;
  },
  async saveKnowledgeBaseConfiguration(data) {
    const response = await axios.put(`/knowledge_base_configuration`, data);
    return response.data;
  },
  async createNewImportedDocumentWithFileUpload(file, onUploadProgress) {
    const formData = new FormData();
    formData.append(file.name, file);

    let handle = null;

    const options = {
      onUploadProgress: function (progressEvent) {
        const proportionCompleted = progressEvent.loaded / progressEvent.total;
        if (onUploadProgress) {
          onUploadProgress(proportionCompleted * 0.2);
        }

        if (progressEvent.loaded === progressEvent.total) {
          if (onUploadProgress) {
            onUploadProgress(0.2);
          }

          let progress = 0.2;
          // Now start an interval to update the progress bar until the file finishes getting processed.
          handle = setInterval(async () => {
            progress += (0.97 - progress) * 0.01;
            if (onUploadProgress) {
              onUploadProgress(progress);
            }
          }, 250);
        }
      },
      onDownloadProgress: function (progressEvent) {
        if (progressEvent.loaded === progressEvent.total) {
          if (handle) {
            clearInterval(handle);
          }
          if (onUploadProgress) {
            onUploadProgress(1.0);
          }
        }
      },
    };

    const response = await axios.post(`/imported_document`, formData, options);
    onUploadProgress(1.0);
    return response.data;
  },
  async queryKnowledgeBase(queryText) {
    const queryParams = {
      query: queryText,
    };

    const response = await axiosWithETAGCache.get(`/knowledge/search`, {
      params: queryParams,
    });

    return response.data;
  },
  async getImportedWebpageSchema() {
    if (globalImportedWebpageSchema) {
      return globalImportedWebpageSchema;
    }
    const response = await axiosWithETAGCache.get(`/imported_webpage/schema`);
    globalImportedWebpageSchema = response.data;
    return response.data;
  },
  async getImportedWebpages(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/imported_webpage`, {
      params: queryParams,
    });

    return response.data;
  },
  async importWebpage(url) {
    const response = await axios.post(`/imported_webpage`, {
      original_url: url,
    });

    return response.data;
  },
  async getImportedWebpage(importedWebpageId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/imported_webpage/${importedWebpageId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveImportedWebpage(importedWebpage) {
    const response = await axios.put(
      `/imported_webpage/${importedWebpage._id}`,
      importedWebpage
    );
    return response.data;
  },
  async deleteImportedWebpage(importedWebpageId) {
    const response = await axios.delete(
      `/imported_webpage/${importedWebpageId}`
    );

    return response.data;
  },
};

export default api;
