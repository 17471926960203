import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";


let globalLLMModels = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getLLMModels() {
        if (globalLLMModels) {
            return globalLLMModels;
        }
        const response = await axiosWithETAGCache.get(`/llm_models`);
        globalLLMModels = response.data;
        return response.data;
    },
}

export default api;

