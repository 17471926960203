// Warning: Experimental library, do not use in production environments.
import StackdriverErrorReporter from 'stackdriver-errors-js';
import {useFrontendConfiguration} from "./FrontendConfigurationProvider";

export let errorHandler = null;

/**
 * The ping provider calls the home endpoint of the API as soon as the application starts up.
 *
 * It's meant to trigger the app to start cold-booting if it needs to, and to continuing
 * pinging it to ensure it stays awake while it is in use.
 *
 * Notably the first ping is done before the authentication flow or APIProvider.
 * By making the first ping happen before login, we can give the backend server time to boot up
 * while the user is logging in, reducing the time the user has to wait significantly.
 */
export const ErrorReportingWrapper = ({children}) => {
    const config = useFrontendConfiguration();

    if (config.frontend.gcp_error_reporting_api_key) {
        errorHandler = new StackdriverErrorReporter();
        errorHandler.start({
            key: config.frontend.gcp_error_reporting_api_key,
            projectId: config.frontend.google_cloud_project,
            service: config.frontend.top_bar_title,
            version: config.version,
            reportUncaughtExceptions: true,
            reportUnhandledPromiseRejections: true,
            disabled: false,
        });
    }

    return <>
        {children}
    </>;
};

