import React, { useContext, useEffect } from "react";
import {api} from "./api";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
const FrontendConfigurationContext = React.createContext(null);

/**
 * The frontend configuration provider is used to fetch the main configuration for the frontend depending
 * on what tenant is loaded.
 */
export const FrontendConfigurationProvider = ({ children }) => {
  const [frontendConfiguration, setFrontendConfiguration] = React.useState();

  useEffect(() => {
    if (!frontendConfiguration) {
        // We call the home endpoint, and wait until it responds at-least twice
        // before declaring the backend is operational and the frontend can be
        // loaded. This prevents issues with cold-booted servers.
        api.home().then(() => {
          api.home().then(() => {
            api.frontendConfiguration().then((frontendFeatures) => {
              setFrontendConfiguration(frontendFeatures);
            });
          });
        });
    }
  }, [frontendConfiguration, api]);

  if (!frontendConfiguration) {
    return <ApplicationBootingPage />;
  }

  return (
    <FrontendConfigurationContext.Provider value={frontendConfiguration}>
      {children}
    </FrontendConfigurationContext.Provider>
  );
};

export const useFrontendConfiguration = useContext.bind(null, FrontendConfigurationContext);
