import axios from "axios";

const api = {
  async translate(sourceText, targetLanguageCode) {
    const postBody = {
      source_text: sourceText,
      target_language_code: targetLanguageCode,
    };

    const response = await axios.post(`/translation`, postBody);

    return response.data;
  },
};

export default api;
