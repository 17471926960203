import React from 'react';
import "./OnboardingPopupWrapper.scss";
import OnboardingChecklist from "./OnboardingChecklist";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import Paper from "@mui/material/Paper";
import {useFrontendConfiguration} from "../../frontend/components/FrontendConfigurationProvider";

let globalCachedChecklistData = null;
let globalCachedAllComplete = null;

export default function OnboardingPopupWrapper({children}) {
    const config = useFrontendConfiguration();

    const api = useAPI();
    const [checklistData, setChecklistData] = React.useState(null);
    const [allComplete, setAllComplete] = React.useState(false);

    React.useEffect(() => {
        if (config.frontend.enable_onboarding) {
            if (globalCachedChecklistData) {
                setAllComplete(globalCachedAllComplete);
                setChecklistData(globalCachedChecklistData);
            } else {
                api.getOnboardingChecklist().then((checklistData) => {
                    let allComplete = true;
                    checklistData.items.map((item) => {
                        if (!item.is_completed) {
                            allComplete = false;
                        }
                    });
                    globalCachedAllComplete = allComplete;
                    globalCachedChecklistData = checklistData;

                    // Only show the checklist in the popup if not all the items have been completed
                    setAllComplete(allComplete);
                    setChecklistData(checklistData);
                });
            }
        }
    }, [api]);

    if (!config.frontend.enable_onboarding) {
        return children;
    }

    return <>
        {children}
        {
            (!allComplete && checklistData) ?
                <Paper className={"onboarding-popup-wrapper"}>
                    <OnboardingChecklist
                        checklistData={checklistData}
                        defaultIsExpanded={false}
                    />
            </Paper> : null
        }
    </>
}